import React, { useEffect } from 'react'
import Layout from '../modules/Layout'
import { useVWOVariant } from '../hooks/useVWO'
import { graphql } from 'gatsby'

const VWOWrapper = (props) => {
  const { campaignNumber } = props
  const variant = useVWOVariant(campaignNumber)
  const layout = variant === '2' ? props.variant1 : props.control

  useEffect(() => {
    console.log('Variant changed:', variant)
    // Any other actions when variant changes
  }, [variant])

  // Conditional rendering if the variant is still being fetched
  if (variant === null) {
    return <div>Loading...</div>
  }

  return (
    <>
      <Layout layout={layout} />
    </>
  )
}

export default VWOWrapper

export const vwo = graphql`
  fragment VWO on ContentfulVwoWrapper {
    id
    contentful_id
    name
    campaignNumber
    control {
      __typename
      ... on ContentfulHero {
        ...Hero
      }
      ... on ContentfulContentGeneric {
        id
        ...ContentGeneric
      }
      ... on ContentfulLayoutLeftRight {
        ...LayoutLeftRight
      }
    }
    variant1 {
      __typename
      ... on ContentfulContentGeneric {
        id
        ...ContentGeneric
      }
      ... on ContentfulLayoutLeftRight {
        ...LayoutLeftRight
      }
    }
  }
`
